import request from '@/utils/request'

export function getAdvertisements(parameter) {
  return request({
    url: '/advertise',
    method: 'post',
    data: parameter
  })
}

export function getAdvertisement(parameter) {
  return request({
    url: '/advertise',
    method: 'get',
    params: parameter
  })
}

export function putAdvertisement(parameter) {
  return request({
    url: '/advertise',
    method: 'put',
    data: parameter
  })
}

export function updateStatus(parameter) {
  return request({
    url: '/advertise/status',
    method: 'post',
    data: parameter
  })
}
export function updateSticky(parameter) {
  return request({
    url: '/advertise/sticky',
    method: 'post',
    data: parameter
  })
}

export function deleteRecord(parameter) {
  return request({
    url: '/advertise',
    method: 'delete',
    params: parameter
  })
}

export function queryMiniAd(parameter) {
  return request({
    url: '/mini/ad/list',
    method: 'post',
    data: parameter
  })
}

export function putMiniAd(parameter) {
  if (parameter.id) {
    return request({
      url: '/mini/ad/update',
      method: 'post',
      data: parameter
    })
  } else {
    return request({
      url: '/mini/ad/add',
      method: 'put',
      data: parameter
    })
  }
}

export function getMiniAd(parameter) {
  return request({
    url: '/mini/ad/detail/' + parameter.id,
    method: 'get',
    // params: parameter
  })
}

export function deleteMiniAd(parameter) {
  return request({
    url: '/mini/ad/delete/' + parameter.id,
    method: 'delete',
    // params: parameter
  })
}
